@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  background: #fff;
  width: 40vw;
  @include screen(xs) {
    width: 70vw;
  }
}

.title {
  text-align: center;

  font-style: normal;
  font-weight: bold;
  font-size: $font-size-lg;
  line-height: 27px;
  color: $title-color;
}

.cards {
  margin-top: 15px;
  height: 245px;
  overflow-y: scroll;

  > div {
    margin-bottom: 15px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.buttons {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  width: 100%;

  @include screen(xs) {
    display: grid;
  }

  > button {
    width: 164px;
    height: 40px;
    margin: 0 16px;

    @include screen(xs) {
      margin-bottom: 10px;
    }

    div {
      display: inline;
    }
  }
}
