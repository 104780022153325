@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  header {
    max-width: 770px;
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;

    .icon {
      display: flex;
      justify-content: flex-end;
    }

    h1 {
      color: $title-color;
      font-size: 24px;
      margin: 0;
    }

    h2 {
      font-weight: normal;
      font-size: 14px;
      margin: 0;
    }
  }

  section {
    margin-top: 16px;
    margin-right: 16px;
    height: 100%;
    width: 100%;

    h3 {
      margin: 0;
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 4px;
    }

    ul {
      box-sizing: border-box;
      background: $background-color;
      padding: 16px;
      border-radius: 8px;
    }

    li {
      margin-bottom: 8px;
    }

    p {
      font-weight: bold;
      font-size: 14px;
      padding-left: 16px;
      position: relative;
    }

    p::before {
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      background: $green;
      border-radius: 100%;
      width: 12px;
      height: 12px;
    }

    strong,
    span {
      display: block;
      color: $error;
      font-size: 12px;
      padding-left: 16px;
    }
  }
}

.reproved {
  p::before {
    background: $error !important;
  }
}

.pending {
  p::before {
    background: #f1ce2e !important;
  }

  p + strong {
    font-weight: normal;
    color: inherit;
  }
}

.documents {
  display: grid;
  justify-content: space-around;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 14px;

  @include screen(sm) {
    grid-template-columns: 1fr;
  }
}

.modal {
  max-width: 800px;
}

.button {
  padding: 10px;
}

@media (max-width: 799px) {
  .modal {
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
  }

  .icon {
    padding: 12px;
  }

  .documents {
    display: flex;
    flex-direction: column;
    max-width: 340px;
    width: 100%;

    .itens {
      max-width: 300px;
      width: 100%;
    }
  }
}
