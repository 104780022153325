@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.mobile {
  @include screen(max-md) {
    position: fixed;
    height: 56px !important;
    width: 100vw;
    z-index: 1000;
  }
}
.header {
  box-sizing: border-box;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: $header-color;
  justify-content: space-between;

  .icon {
    @include screen(min-lg) {
      display: none;
    }
  }

  @include screen(max-md) {
    height: 100%;
    padding: 10px;
  }
}

.logoContainer {
  height: 40px;

  img {
    margin-left: 46px;
    width: 165px;

    @include screen(max-md) {
      margin-left: 16px;
    }
  }
}

.navbar {
  ul {
    display: flex;
    flex-direction: row;
    margin-right: 26px;

    @include screen(max-md) {
      margin-right: 0;
    }
  }

  button {
    font-size: $font-size;
    color: $dark-grey-color;
    margin-left: 5px;
    border: 0;
    background: transparent;
    cursor: pointer;
  }
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 24px;
  text-decoration: none;

  @include screen(max-md) {
    margin-right: 0;
  }

  img {
    width: 28px;
  }

  .profileLabel {
    margin-left: 8px;
  }

  &:hover {
    color: $secondary-color;
    font-size: $font-size + 1;
  }

  .dashboardIcon {
    margin-right: 8px;
  }
}

.profile {
  position: relative;

  span {
    width: 150px;
    background: $header-color;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: calc(100% + 20px);
    left: 25%;
    transform: translateX(-50%);
    color: #312e38;
    z-index: 4;

    @include screen(max-md) {
      left: -30px;
    }

    &::before {
      content: '';
      border-style: solid;
      border-color: $header-color transparent;
      border-width: 0px 6px 6px 6px;
      bottom: 100%;
      position: absolute;
      left: 75%;
    }
    ul {
      display: flex;
      flex-direction: column;
    }
  }
}

.noShow {
  @include screen(max-md) {
    display: none;
  }
}

.show {
  @include screen(min-lg) {
    display: none;
  }
}
