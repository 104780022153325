@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 36px 100px 70px 80px;

  h1 {
    margin: 0 0 16px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.18px;
    color: $text-color;
  }

  ProponentsForm {
    padding: 50px;
  }

  .info,
  .savedProponents {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .info {
    width: 100%;
  }

  .secondTitle {
    margin: 36px 0 16px 0;
  }

  .othersProponents {
    display: flex;
    flex-wrap: wrap;
    gap: 28px;

    button[class*='AddProponent_container'] {
      height: 247px;

      @include screen(max-md) {
        max-width: unset;
        width: 90%;
      }
    }
  }

  @include screen(max-md) {
    padding: 36px 0 70px 0;
    align-items: center;

    .othersProponents {
      justify-content: center;
    }

    .info {
      align-items: center;
    }
  }
}
