@import 'src/assets/styles/partials/variables.scss';
@import 'src/assets/styles/partials/mixins.scss';

.options {
  position: absolute;
  top: 65px;
  visibility: hidden;
  display: none;
  transition: opacity 0s, transform 0.1s ease-in-out;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #919aad;
  font-size: $font-size-small;
  overflow: hidden;
  width: 100%;

  > .option {
    display: flex;
    align-items: center;
    background-color: $white-color;
    padding-left: 10px;
    height: 32px;

    &:hover {
      background-color: adjust-color($color: $aceppted, $lightness: 30%);
      cursor: pointer;
    }

    &:focus {
      background-color: adjust-color($color: $aceppted, $lightness: 10%);
      outline: 0;
    }
  }
}

.open {
  max-height: 200px;
  overflow-y: scroll;
  visibility: visible;
  display: block;
  z-index: 2;
}

.tooManyItems {
  overflow-y: scroll;
}

.alerter {
  position: relative;
}

.placeholderTop {
  font-size: 14px;
  color: #23355a;
  opacity: 0.8;
  top: 16px;
  left: 11.5px;
  position: absolute;
  pointer-events: none;
}

@include screen(xs) {
  .max,
  .xlarge,
  .large,
  .medium,
  .xmedium,
  .small,
  .xsmall {
    width: 100%;
  }
}

@include screen(sm) {
  .max,
  .xlarge,
  .large {
    width: 100%;
  }
  .medium,
  .small,
  .xsmall {
    width: 45%;
  }
}

@include screen(min-md) {
  .xsmall {
    width: 15.1%;
  }

  .small {
    width: 32%;
  }

  .medium {
    width: 49%;
  }
  .xmedium {
    width: 302px;
  }

  .large {
    width: 65.8%;
  }

  .xlarge {
    width: 75%;
  }

  .max {
    width: 100%;
  }
}
