@import '../../../../assets/styles/partials/variables.scss';
@import '../../../../assets/styles/partials/mixins.scss';

.container {
  background: #fff;
  > img {
    width: 53px;
    height: 53px;
    background-color: $light-grey;
    border-radius: 50%;
    padding: 8px;
  }
}

.shadow {
  background: #0008;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.highlight {
  position: relative;
  z-index: 150;
}

.navigation {
  position: fixed;
  top: 24%;
  left: 53%;
  z-index: 150;
  max-width: 506px;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 24px 36px;
  text-align: center;

  @include screen(max-sm) {
    top: 30%;
    left: 50%;
    width: 80%;
    padding: 24px 16px;

    transform: translateX(-50%);
  }
  @include screen(xxl) {
    top: 20%;
    left: 53%;
  }

  h1 {
    font-size: 20px;
    margin: 0;
    margin-bottom: 8px;
    color: $title-color;
    @include screen(max-sm) {
      font-size: 18px;
    }
  }

  p {
    font-size: 16px;
    > p {
      margin-top: 16px;
    }
  }
}

.navigation-1 {
  left: 55%;
  top: 30%;

  @include screen(max-sm) {
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
  @include screen(xxl) {
    top: 29%;
  }
}

.navigation-2 {
  top: 23%;
  left: 20%;

  @include screen(max-sm) {
    left: 50%;
    top: 53%;
    transform: translateX(-50%);
  }
  @include screen(xxl) {
    top: 20%;
    left: 29%;
  }
}

.navigation-3 {
  top: 18%;
  left: 53%;

  @include screen(max-sm) {
    left: 50%;
    top: 21%;
    transform: translateX(-50%);
  }
  @include screen(xxl) {
    left: 55%;
  }
}

.step-3 {
  header[aria-label='header principal'] {
    position: relative;
    z-index: 150;
  }
}
.arrow {
  position: absolute;
  @include screen(max-sm) {
    width: 92px;
  }
  @include screen(xxl) {
    width: 145px;
  }
}
.arrow-0 {
  left: 42%;
  top: 20%;
  transform: rotate(3deg);
  @include screen(max-sm) {
    top: 10%;
    left: 2%;

    transform: rotate(39deg);
  }
}

.arrow-1 {
  left: 45vw;
  top: 68%;
  transform: rotate(333deg);
  @include screen(max-sm) {
    top: 34%;
    left: 71%;
    transform: rotate(235deg);
  }
  @include screen(xxl) {
    left: 46%;
    top: 69%;
    transform: rotate(337deg);
  }
}
.arrow-2 {
  left: 60%;
  top: 63%;
  transform: rotate3d(-1, 2, 0, 180deg);
  @include screen(max-sm) {
    left: 2%;
    top: 25%;
    transform: rotate(30deg);
  }

  @include screen(xxl) {
    left: 56%;
    top: 61%;
  }
}
.arrow-3 {
  left: 44%;
  top: 11%;
  transform: rotate(32deg);
  @include screen(max-sm) {
    left: 0;
    top: 6%;
  }

  @include screen(xxl) {
    left: 46%;
    top: 13%;
  }
}
.addProponent {
  display: none !important;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 32%;

  @include screen(max-md) {
    display: block !important;
  }
}
