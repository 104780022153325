@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  height: 64px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1.25rem 2.25rem 0;
  background-color: $background-color;
  border-radius: 4px;

  @include screen(max-sm) {
    background-color: inherit;
    height: 100%;
    padding: 8px;
  }

  div {
    cursor: pointer;
    border-radius: 50%;
  }

  > .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include screen(max-sm) {
      margin-bottom: 12px;
      flex-direction: column;
    }

    div {
      width: calc(12px + 1.5vw);
      height: calc(12px + 1.5vw);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $light-grey;
      font-size: calc(10px + 0.5vw);
      color: $brow-grey;
      font-weight: 700;
      @include screen(max-sm) {
        padding: 8px;
        font-size: 14px;
      }
    }

    > p {
      color: $text-color;
      font-size: calc(10px + 0.3vw);
      margin: auto 8px;

      @include screen(max-sm) {
        font-size: 14px;
      }
    }
    &:nth-child(5) {
      margin-left: 10px;
    }
  }

  .verticalLine {
    width: calc(25px + 5vw);
    height: 2px;
    margin-right: 8px;

    background-color: $light-grey;

    @include screen(max-sm) {
      width: calc(20px + 5vw);
      top: 30%;
    }
  }
  .verticalLineGreen {
    background-color: $sea-green;
  }

  .help {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 15vw;

    border-radius: 50%;
    background-color: $white-color;

    img {
      width: 36px;
      height: 36px;
    }

    @include screen(max-sm) {
      position: fixed;
      bottom: 70px;
      left: 88vw;
      box-shadow: 4px 4px 10px $dark-color;

      @include screen(xs) {
        left: 82vw;
        bottom: 80px;
      }
    }

    @include screen(md) {
      left: 8vw;
    }
    @include screen(xxl) {
      left: 21vw;
    }
  }

  .green {
    div {
      background-color: $sea-green;

      color: $white-color;
      font-weight: 700;
    }

    > p {
      color: $black-color;
    }
  }

  > .green + .green:before {
    background-color: $sea-green;
  }

  .boldFont {
    > p {
      font-weight: 700;
    }
  }

  .disabled {
    cursor: default;

    > div {
      background-color: #eee;
      cursor: inherit;
    }

    > p {
      color: #888;
    }
  }

  div.item::after {
    width: calc(30px + 5vw);
    height: 2px;
    margin-right: 8px;

    background-color: $light-grey;
  }
}
.verticalLine-1 {
  left: 12vw;
}
.verticalLine-2 {
  left: 34vw;
}
.verticalLine-3 {
  left: 57vw;
}

.cursor {
  div {
    cursor: not-allowed;
  }
}
