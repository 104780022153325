@import '../../assets/styles/partials/variables.scss';

.container {
  display: flex;
  flex-direction: column;

  font-style: normal;
  font-size: 14px;
  color: $text-color;

  .title {
    font-weight: bold;
    font-size: 14px;
  }

  p {
    font-weight: normal;
  }
}
