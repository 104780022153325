@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: rotate 1s linear infinite;
  background: $title-color;
  border-radius: 50%;
  height: 58px;
  width: 58px;
  position: relative;
  margin: auto;
  margin-top: 20%;
}

.spinner::before,
.spinner::after {
  content: '';
  position: absolute;
}

.spinner::before {
  border-radius: 50%;
  background: linear-gradient(
        0deg,
        hsla(0, 0%, 100%, 1) 50%,
        hsla(0, 0%, 100%, 0.9) 100%
      )
      0% 0%,
    linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0.9) 0%,
        hsla(0, 0%, 100%, 0.6) 100%
      )
      100% 0%,
    linear-gradient(
        180deg,
        hsla(0, 0%, 100%, 0.6) 0%,
        hsla(0, 0%, 100%, 0.3) 100%
      )
      100% 100%,
    linear-gradient(
        360deg,
        hsla(0, 0%, 100%, 0.3) 0%,
        hsla(0, 0%, 100%, 0) 100%
      )
      0% 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.spinner::after {
  background: white;
  border-radius: 50%;
  top: 11%;
  bottom: 11%;
  left: 11%;
  right: 11%;
}
